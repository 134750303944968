import Cookies from 'js-cookie';


$(document).on('turbolinks:load', function() {
	const regex = RegExp('resources\/.*');

	if (regex.test(window.location.href)) {
		$("body").bind("mouseleave",function(){
	    	if(!Cookies.get( "exit_demo_popup" )) {
	      	/* do somthing (ex. init modal) */
			window.$('#exit_demo_modal').modal('show');
	      	/* set cookie so this does not repeat */ 

	      	Cookies.set( "exit_demo_popup", "1", { path: '/', expires: 14 });
	      	console.log("cookie set");
	      	/* uncomment above when testing is done */
	    	} 
	 	})
    }
});